const jQuery = require("jquery");
const tooltip = require("tooltip");
// @ts-ignore
window.$ = window.jQuery = jQuery;
import axios from "axios";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import "jquery-validation";

$(document).ready(function() {
  const getUrlParameter = (sParam: string) => {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  };

  tooltip({
    showDelay: 100,
    style: {
      padding: 5
    }
  });
  const mobileMenuToggle = document.getElementById("mobile-menu-toggle");
  const mobileMenu = document.getElementById("mobile-menu");

  if (mobileMenuToggle) {
    mobileMenuToggle.onclick = () => {
      mobileMenuToggle.classList.toggle("open");
      mobileMenu.classList.toggle("open");
    };
  }

  $(".hero__carousel").owlCarousel({
    animateOut: "fadeOut",
    animateIn: "fadeIn",
    loop: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 10000
  });

  // @ts-ignore
  $(".owl-carousel:not(.paragon__how__carousel)").owlCarousel({
    loop: true,
    nav: true,
    items: 1,
    responsive: {
      768: {
        items: 3
      }
    }
  });
  $(".paragon__how__carousel").owlCarousel({
    loop: true,
    nav: true,
    items: 1,
    dots: true,
    responsive: {
      768: {
        dots: false,
        nav: false,
        items: 3,
        mouseDrag: false
      }
    }
  });
  let headerHeight = 89;

  if (window.innerWidth >= 768) {
    headerHeight = 125;
  }

  $(window).on("hashchange load", function() {
    (function() {
      const $anchor = $(":target");

      if ($anchor.length > 0) {
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: $anchor.offset().top - headerHeight
            },
            200
          );
      }
    })();
  });

  $(document).on("click", 'a[href^="#"]', function(event) {
    event.preventDefault();

    const mobileMenuToggle = document.getElementById("mobile-menu-toggle");
    const mobileMenu = document.getElementById("mobile-menu");
    mobileMenuToggle.classList.toggle("open");
    mobileMenu.classList.toggle("open");
    // @ts-ignore
    const href = $.attr(this, "href");
    if (window.location.pathname !== "/") {
      window.location.href = `/${href}`;
    }

    $("html, body").animate(
      {
        scrollTop: $(href).offset().top - headerHeight
      },
      500
    );
  });

  $(document).on("click", ".hint", function(event) {
    // @ts-ignore
    const href = $.attr(this, "href");
    if (window.location.pathname !== "/") {
      event.preventDefault();
    }
    $("html, body").animate(
      {
        scrollTop: $("#price-list").offset().top - headerHeight
      },
      500
    );
  });

  $(
    ".certificate__card__content__button, .paragon__card__content__button"
  ).click(() => {
    $(".paragon__card__footer__content")
      .stop()
      .slideDown();
    $(".certificate__card__footer__content")
      .stop()
      .slideDown();
    $("html, body").animate(
      {
        scrollTop: $("#postal-order").offset().top - headerHeight
      },
      500
    );
  });

  $(".faq__item").click(function(this) {
    $(this)
      .find(".faq__item__answer")
      .stop()
      .slideToggle("fast");
  });

  $(".hint").mouseenter(function(this) {
    $(this)
      .find(".hint__text")
      .show();
  });

  $(".hint").mouseleave(function(this) {
    $(this)
      .find(".hint__text")
      .hide();
  });

  $(".products__footer__link").click(() => {
    $(".products__footer__content")
      .stop()
      .slideToggle();
  });
  $(".paragon__card__footer__link").click(() => {
    $(".paragon__card__footer__content")
      .stop()
      .slideToggle();
  });
  $(".certificate__card__footer__link").click(() => {
    $(".certificate__card__footer__content")
      .stop()
      .slideToggle();
  });

  const lookupSfik = async (sfik: string) => {
    const baseUrl = "https://ke-eet.appspot.com/api/eetrecordinfo";
    $("#sfik-error").removeClass("show");
    try {
      const result = await fetch(`${baseUrl}?sfik=${sfik}`).then(res =>
        res.json()
      );
      console.log(result);
      if (result) {
        for (var key in result) {
          let value = result[key];

          if (key === "amount") {
            value = value + " Kč";
          } else if (key === "dat_trzby") {
            value = new Date(value).toLocaleString();
          } else if (key === "regime") {
            value = value ? "ověřující" : "bežný";
          }

          $(`#fill_${key}`).text(value);
        }

        $("#sfik-link").attr("href", `?sfik=${sfik}`);
      }
    } catch (err) {
      console.log(err);
      $(`#fill_sfik_error`).text(sfik);
      $("#sfik-error").addClass("show");
    }
  };

  $("#search-form").on("submit", e => {
    e.preventDefault();
    const sfik = $("#sfik").val();
    if (sfik) {
      window.location.href = `/search-result.html?sfik=${sfik}`;
    }
  });

  const sfikFromUrl = getUrlParameter("sfik") as string;
  if (sfikFromUrl) {
    lookupSfik(sfikFromUrl);
  }

  $("#show-search").click(e => {
    e.preventDefault();
    $(".header__mobile-search").toggleClass("open");
  });

  // @ts-ignore
  $("#discount-form").validate({
    submitHandler: function() {
      const $inputs = $("#discount-form :input");

      const values = {};
      $inputs.each(function() {
        const name = (this as any).name;
        if (name) {
          values[name] = $(this).val();
        }
      });

      $("#discount-form input[type='submit']").attr("disabled", "true");
      $("#discount-form input[type='submit']").val("Odesílání");
      // @ts-ignore

      axios
        .post(
          "https://us-central1-eetparagon-aec66.cloudfunctions.net/webApi/api/v1/discountForm",
          values
        )
        .then(() => {
          window.location.href = "/sleva-uspech.html";
        })
        .catch(e => {
          $("#discount-form input[type='submit']").removeAttr("disabled");
          $("#discount-form input[type='submit']").val("Odeslat");
        });
    },
    rules: {
      company_name: {
        required: true
      },
      name: {
        required: true
      },
      surname: {
        required: true
      },
      vat_no: {
        required: true
      },
      discount_code: {
        required: true
      },
      email: {
        required: true,
        email: true
      },
      phone: {
        required: true,
        minlength: 9,
        maxlength: 9,
        number: true
      }
    },
    messages: {
      company_name: "Povinné pole",
      name: "Povinné pole",
      surname: "Povinné pole",
      vat_no: "Povinné pole",
      discount_code: "Povinné pole",
      email: {
        required: "Povinné pole",
        email: "Pole musí být email"
      },
      phone: {
        required: "Povinné pole",
        minlength: "Číslo musí mít 9 znaků",
        maxlength: "Číslo musí mít 9 znaků",
        number: "Musí obsahovat pouze číslice"
      }
    }
  });

  function fixProductBoxHeight() {
    let boxHeight = 0;
    $(".products__card__content").each(function() {
      console.log(this.scrollHeight, this.scrollHeight > boxHeight);
      if (this.scrollHeight > boxHeight) {
        boxHeight = this.scrollHeight;
        console.log(boxHeight);
      }
    });

    $(".products__card__content").each(function() {
      $(this).css({ minHeight: boxHeight });
    });
  }

  fixProductBoxHeight();

  $("#show-preview").click(event => {
    event.stopPropagation();
    $(".header__search__preview").slideDown();
  });

  $(window).click(function() {
    $(".header__search__preview").slideUp();
  });

  $(".header__search__preview").click(function(event) {
    event.stopPropagation();
  });
});
